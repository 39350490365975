import React from "react";
import { useAtom } from "jotai/index";
import {
  cardList_atom,
  newData_atom,
  searchParam_atom,
} from "../../../../data/atom/dataManage/cardInquiry/cardInquiry_atom";
import moment from "moment/moment";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";

const CardInquiryRow = props => {
  const { data, index, getCardUserList, getAccountList, searchCard, isNew } = props;
  const [searchParam, setSearchParam] = useAtom(searchParam_atom);
  const [cardList, setCardList] = useAtom(cardList_atom);
  const [newData, setNewData] = useAtom(newData_atom);

  const deleteCard = useMutation(data => axios.post("/api/data/card/delete", data), {
    onSuccess: res => {
      searchCard.mutate(searchParam);
    },
  });

  const onChangeEventHandler = (key, value) => {
    if (key === "cardNo" || key === "saupno") {
      value = value.replace(/[^0-9]/g, "");
    } else if (key === "priceWon") {
      value = Number(String(value).replace(/[^0-9]/g, ""));
    } else if (key === "currDate") {
      value = moment(value).format("YYYY-MM-DD HH:mm:ss");
    }

    if (isNew) {
      setNewData({ ...newData, [key]: value });
    } else {
      let list = cardList.map(el => el);
      list[index][key] = value;
      list = list.sort((a, b) => a.account - b.account);

      let length = list.length;
      // null 항목 맨 뒤로 이동
      for (let i = 0; i < length; i++) {
        if (list[i].seqNo === null) {
          // null 요소를 잘라서 배열의 끝에 추가
          list.push(...list.splice(i, 1));
          i--;
          length--;
        }
      }

      setCardList(list);
    }
  };

  const btnDelete = (deleteEl, idx) => {
    if (isNew) {
      setNewData({});
    } else {
      if (confirm("삭제하시겠습니까?")) {
        deleteCard.mutate([deleteEl]);
      }
    }
  };

  return (
    <tr>
      <td>{index === -1 ? "" : index + 1}</td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={
            data.gubun === "55"
              ? data.cardNo.replace(/^(\d{4})(\d{6})(\d{5})$/, `$1-$2-$3`)
              : data.cardNo.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3-$4`)
          }
          onChange={e => onChangeEventHandler("cardNo", e.target.value.replace(/-/g, ""))}
          maxLength={19}
        />
      </td>
      <td>
        <input
          type="datetime-local"
          className="form-control filter-input"
          value={moment(data.currDate).format("YYYY-MM-DD HH:mm:ss")}
          onChange={e => onChangeEventHandler("currDate", e.target.value)}
          min={"1900-01-01"}
          max={"2099-12-31"}
          style={{ borderColor: "#c5c5c5" }}
        />
      </td>
      <td>
        <select
          className="form-select table-select"
          value={data.userId}
          onChange={e => onChangeEventHandler("userId", e.target.value)}
        >
          {getCardUserList?.map(el => {
            return <option value={el.key}>{el.value}</option>;
          })}
          <option value={data.userId} hidden={true}></option>
        </select>
      </td>
      <td>
        <select
          className="form-select table-select"
          value={data.account}
          onChange={e => onChangeEventHandler("account", e.target.value)}
        >
          {getAccountList?.map(el => {
            return <option value={el.key}>{el.value}</option>;
          })}
          <option value={data.account} hidden={true}></option>
        </select>
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input text-center"
          value={data.priceWon.toLocaleString()}
          onChange={e => onChangeEventHandler("priceWon", Number(e.target.value.replace(/,/g, "")))}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={data.remark}
          onChange={e => onChangeEventHandler("remark", e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input text-center"
          value={data.saupno.replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`)}
          onChange={e => onChangeEventHandler("saupno", e.target.value.replace(/-/g, ""))}
          maxLength={12}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control table-input"
          value={data.storeName}
          onChange={e => onChangeEventHandler("storeName", e.target.value)}
        />
      </td>
      <td>{data.inputId ? getCardUserList?.find(el => el.key === data.inputId)?.value : ""}</td>
      <td className="text-center cursor-pointer" onClick={() => btnDelete(data, index)}>
        <img src={process.env.PUBLIC_URL + "/images/delete.png"} alt="" />
      </td>
    </tr>
  );
};

export default React.memo(CardInquiryRow);
