import React from "react";
import { useAtom, useSetAtom } from "jotai";
import {
  ocrNapbuPrintParam_atom,
  ocrPrintParam_atom,
  result_atom,
  ydDivide_atom,
  ydDivideInit,
} from "../../../../data/atom/workManage/transferTax/transferTax_atom";

export default function YdDivide() {
  const [ydDivide, setYdDivide] = useAtom(ydDivide_atom);
  const [result, setResult] = useAtom(result_atom);
  const setOcrPrintParam = useSetAtom(ocrPrintParam_atom);
  const setOcrNapbuPrintParam = useSetAtom(ocrNapbuPrintParam_atom);

  const onChangeEventHandler = (key, value) => {
    if (key !== "flag") {
      value = Number(value.replace(/[^0-9]/g, "").replace(/,/g, ""));
    }

    if (key === "flag") {
      if (value) {
        alert(
          "세액을 분납하는 것으로 선택하셨습니다.\n" +
            "세액을 분납하는 경우 다음과 같은 원칙을 지켜주시기 바랍니다.\n" +
            "\n" +
            "1. 1차 납부세액은 신고세액의 50%이상이어야 합니다\n" +
            "2. 2차 납부세액의 최저 한도금액은 1,000만원 입니다\n" +
            "3. 양도소득세만 분납 가능하며 주민세는 분납할 수 없습니다."
        );
        setYdDivide({
          ...ydDivide,
          [key]: value,
          input1: +(result.napbuCost / 2).toFixed(0),
          input2: +result.napbuCost - +(result.napbuCost / 2).toFixed(0),
        });
        setOcrPrintParam(prev=> ({ ...prev, asDivideFlag: 'Y', divide: 'Y',
          taxSum1: +(result.napbuCost / 2).toFixed(0),
          taxSum2: +result.napbuCost - +(result.napbuCost / 2).toFixed(0),
          yangdoTax1: +(result.napbuCost / 2).toFixed(0),
          yangdoTax2: +result.napbuCost - +(result.napbuCost / 2).toFixed(0),}));

        setOcrNapbuPrintParam(prev=> ({ ...prev, asDivideFlag: 'Y' }));
      } else {
        setYdDivide(ydDivideInit);
        setOcrPrintParam(prev=> ({ ...prev, asDivideFlag: 'N', divide: 'N',
          input1: +(result.napbuCost / 2).toFixed(0),
          input2: +result.napbuCost - +(result.napbuCost / 2).toFixed(0),
          yangdoTax1: +(result.napbuCost / 2).toFixed(0),
          yangdoTax2: +result.napbuCost - +(result.napbuCost / 2).toFixed(0),}));
        setOcrNapbuPrintParam(prev=> ({ ...prev, asDivideFlag: 'N' }));
      }
    } else if (key === "input1") {
      if (value > +result.napbuCost) {
        alert("분할 납부세액은 납부해야할 최종 양도세를 초과할 수 없습니다.");
      } else {
        setYdDivide({ ...ydDivide, [key]: value, input2: +result.napbuCost - +value });
        setOcrPrintParam(prev => ({
          ...prev,
          taxSum1: value,
          yangdoTax1: value,
          taxSum2: +result.napbuCost - +value,
          yangdoTax2: +result.napbuCost - +value,
        }));
      }
    } else if (key === "input2") {
      if (value > +result.napbuCost) {
        alert("분할 납부세액은 납부해야할 최종 양도세를 초과할 수 없습니다.");
      } else {
        setYdDivide({ ...ydDivide, [key]: value, input1: +result.napbuCost - +value });
        setOcrPrintParam(prev => ({
          ...prev,
          taxSum2: value,
          yangdoTax2: value,
          taxSum1: +result.napbuCost - +value,
          yangdoTax1: +result.napbuCost - +value,
        }));
      }
    }
  };

  return (
    <div className="card card-main w-50 h-100">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5 className="card-title m-0">양도세 분할 납부</h5>
      </div>

      <div className="card-body">
        <div className="flex-center-between">
          <div className="flex-center-center align-items-center">
            <input
              type="checkbox"
              checked={ydDivide.flag}
              onChange={e => onChangeEventHandler("flag", e.target.checked)}
              id="ydDivideFlagCheck"
              disabled={result.napbuCost < 10000000}
            />
            <label className="filter1-label" htmlFor="ydDivideFlagCheck">
              &nbsp;양도세 분할납부 (1천만원 이상시 분납가능)
            </label>
          </div>
        </div>
        <table className="table table-bordered table-responsive my-table">
          <thead>
            <tr>
              <th>1차 납부세액</th>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={ydDivide.input1.toLocaleString()}
                  onChange={e => onChangeEventHandler("input1", e.target.value)}
                  onBlur={() => {
                    if (ydDivide.input1 < Number((result.napbuCost / 2).toFixed(0))) {
                      alert("1차 납부세액은 납부할 세액의 50% 이상이어야 합니다.");
                      setYdDivide({
                        ...ydDivide,
                        input1: +(result.napbuCost / 2).toFixed(0),
                        input2: +result.napbuCost - +(result.napbuCost / 2).toFixed(0),
                      });
                    }
                  }}
                  disabled={!ydDivide.flag}
                />
              </td>
            </tr>
            <tr>
              <th>2차 납부세액</th>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={ydDivide.input2.toLocaleString()}
                  onChange={e => onChangeEventHandler("input2", e.target.value)}
                  onBlur={() => {
                    if (ydDivide.input2 < 10000000) {
                      alert("2차 납부세액의 최저 한도금액은 1000만원입니다.");
                      setYdDivide({
                        ...ydDivide,
                        input1: +(result.napbuCost / 2).toFixed(0),
                        input2: +result.napbuCost - +(result.napbuCost / 2).toFixed(0),
                      });
                    }
                  }}
                  disabled={!ydDivide.flag}
                />
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
}
