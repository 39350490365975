import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Badge from "react-bootstrap/Badge";
import { DaumPostcode } from "../../../../components/daumPostcode/daumPostcode";
import moment from "moment";
import Tabs from "react-bootstrap/Tabs";
import { CallHistory } from "../customerManageDetailSub/callHistory";
import { decrypt } from "../../../../util/decrypt/decrypt";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "../../../../util/axios/axios";
import { RegisteredDelivery } from "../customerManageDetailSub/registeredDelivery";
import { SMSHistory } from "../customerManageDetailSub/smsHistory";
import { TransactionHistory } from "../customerManageDetailSub/transactionHistory";
import { OrderManagement } from "../customerManageDetailSub/orderManagement";
import { OldCall } from "../customerManageDetailSub/oldCall";
import { OldSMS } from "../customerManageDetailSub/oldSMS";
import { BusinessRegistration } from "../customerManageDetailSub/businessRegistration";
import { TaxCalcuationRequset } from "../customerManageDetailSub/taxCalcuationRequset";
import { InfomationChangeHistory } from "../customerManageDetailSub/infomationChangeHistory";
import { useGetCustGubun } from "../../../../api/code/useGetCustGubun";
import { useGetEmail } from "../../../../api/code/useGetEmail";
import { useGetBank } from "../../../../api/code/useGetBank";
import { useGetPartner } from "../../../../api/code/useGetPartner";
import { useGetUp } from "../../../../api/code/useGetUp";
import { useGetDb } from "../../../../api/code/useGetDb";
import { useGetCorp } from "../../../../api/code/useGetCorp";
import { useAtom } from "jotai/index";
import {
  customerManage_isDuplicateCheck_atom,
  customerManage_mainData_atom,
  customerManage_mainDataInit_atom,
  customerManage_selectedCustomer_atom,
  customerManage_tapList_atom,
} from "../../../../data/atom/workManage/customerManage/customerManage_atom";
import { useNavigate } from "react-router-dom";
import { SideGolfIssueModal } from "./modal/sideGolfIssueModal";
import { SideSearchOrderModal } from "./modal/sideSearchOrderModal";
import { MemberShip } from "../customerManageDetailSub/memberShip";
import { regex_only_number } from "../../../../util/validation";
import { useGetUserIdQ } from "../../../../api/code/useGetUserIdQ";
import { SideMessageModal } from "./modal/sideMessageModal";
import { useGetVip } from "../../../../api/code/useGetVip";
import { useSidebarOnClickEvent } from "../../../../util/sidebarOnClickEvent/sidebarOnClickEvent";
import { ReasonChangeRequestModal } from "./modal/reasonChangeRequestModal";
import { DuplicateCheckModal } from "./modal/duplicateCheckModal";
import { useGetEmptyPartner } from "../../../../api/code/useGetEmptyPartner";
import { WebOrder } from "../customerManageDetailSub/WebOrder";
import { oldToNewPhoneFormat } from "../../../../util/oldToNewPhoneFormat";
import PartnerSelect from "../../../../components/select/PartnerSelect";
import lockAuth from "../../../../util/lockAuth";

export const CustomerManageDetail = ({
  customerGetDetail,
  btn_search,
  btn_search_treeNode,
  isSendSMSModalOpen,
  setIsSendSMSModalOpen,
}) => {
  const defaultURL = process.env.PUBLIC_URL;
  const navigate = useNavigate();
  const { sidebarOnClickEvent } = useSidebarOnClickEvent();

  const [tapList, setTapList] = useAtom(customerManage_tapList_atom);
  const [selectedCustomer, setSelectedCustomer] = useAtom(customerManage_selectedCustomer_atom);
  const [mainData, setMainData] = useAtom(customerManage_mainData_atom);

  // 신규등록 중복체크 여부
  const [isDuplicateCheck, setIsDuplicateCheck] = useAtom(customerManage_isDuplicateCheck_atom);

  const [messageList, setMessageList] = useState([]); // 우측패널 - Message
  const [golfIssueList, setGolfIssueList] = useState([]); // 우측패널 - 골프장 특이사항

  // 서브데이터 탭 선택
  const [selectedSubCustomerData, setSelectedSubCustomerData] = useState("통화내역");

  // 메인데이터 주소찾기 메뉴 오픈 여부
  const [addressIsOpen, setAddressIsOpen] = useState([false, false]);
  // 사이드 메뉴 오픈 여부
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState([false, false, false, false]);

  // 메인데이터 중복체크 모달
  const [isDuplicateCheckModalOpen, setIsDuplicateCheckModalOpen] = useState({
    data: null,
    isOpen: false,
  });

  // 메인데이터 삭제 모달
  const [isMainDataDeleteModalOpen, setIsMainDataDeleteModalOpen] = useState({
    data: null,
    isOpen: false,
  });
  //메인데이터 저장 모달
  const [isMainDataRegisterModalOpen, setIsMainDataRegisterModalOpen] = useState({
    data: null,
    isOpen: false,
  });

  // 사이드 Message 모달
  const [isSideMessageModalOpen, setIsSideMessageModalOpen] = useState({
    isOpen: false,
    data: null,
  });
  // 사이드 골프장특이사항 모달
  const [isSideGolfIssueModalOpen, setIsSideGolfIssueModalOpen] = useState({
    isOpen: false,
    data: null,
  });
  // 사이드 직원조회 검색 데이터
  const [sideCustSearchData, setSideCustSearchData] = useState({
    // partner: decrypt("userInfo").loginId,
    partner: "",
  });
  // 사이드 주문현황 모달
  const [isSideSearchOrderModalOpen, setIsSideSearchOrderModalOpen] = useState({
    isOpen: false,
    data: null,
  });
  // 사이드 주문현황 검색 데이터
  const [sideOrderSearchData, setSideOrderSearchData] = useState({
    // date: moment().subtract(3, "months").format("YYYY-MM-DD"),
    date: moment().subtract(3, "month").format("YYYY-MM-DD"),
    gubun: "",
    keyword: "",
  });

  const getCustGubun = useGetCustGubun(); // 고객분류1
  const getEmail = useGetEmail();
  const getBank = useGetBank();
  const getPartner = useGetPartner(); // 담당딜러 userPermit  99 일 때
  const getEmptyPartner = useGetEmptyPartner(); // 담당딜러 userPermit 99가 아닐 때
  const getUserId = useGetUserIdQ(); // 舊담당
  const getUp = useGetUp();
  const getDb = useGetDb(); // 명단출처
  const getCorp = useGetCorp();
  const getVip = useGetVip(); // 고객분류2
  const getUserIdQ = useGetUserIdQ();

  // 고객관리-우측패널 message 조회
  const customerGetMessage = useQuery(
    ["customerGetMessage"],
    async () => await axios.get("/api/work/customer/right/getMessage"),
    {
      onSuccess: res => {
        setMessageList(res.data.result);
      },
      refetchInterval: 1000 * 60,
    }
  );

  // 고객관리-우측패널 message 확인 후 badge 초기화
  const customerCheckMessage = useMutation(
    data => axios.post("/api/work/customer/right/checkMessage", data),
    {
      onSuccess: res => {
        setMessageList(res.data.result);
      },
    }
  );

  // 고객관리-우측패널 골프장 특이사항 조회
  const customerGetGolfIssue = useQuery(
    ["customerGetGolfIssue"],
    () => axios.get("/api/work/customer/right/getGolfIssue").then(res => res.data.result),
    {
      onSuccess: res => {
        setGolfIssueList(res);
      },
    }
  );

  // 고객관리-우측패널 직원조회
  const customerSearchEmp = useMutation(data =>
    axios.get("/api/work/customer/right/searchEmp", { params: data }).then(res => res.data.result)
  );

  // 고객관리-우측패널 주문현황 조회
  const customerSearchOrder = useMutation(data =>
    axios.get("/api/work/customer/right/searchOrder", { params: data }).then(res => res.data.result)
  );

  // 고객 중복체크
  const customerCheckDupl = useMutation(data => axios.post("/api/work/customer/checkDupl", data), {
    onSuccess: res => {
      if (res.data.result.length !== 0) {
        alert("중복 고객이 있습니다.");

        setIsDuplicateCheckModalOpen({
          data: res.data.result,
          isOpen: true,
        });

        setIsDuplicateCheck(false);
      } else {
        alert("중복 고객이 없습니다.");
        setIsDuplicateCheck(true);
      }
    },
  });

  useEffect(() => {
    if (selectedCustomer !== "-1" && selectedCustomer !== "0") {
      customerGetDetail.mutateAsync(selectedCustomer).then(res => {
        customerSearchEmp.mutate({ partner: res.data.result.partner });
      });
    }
  }, [selectedCustomer]);

  const btn_tapClick = data => {
    if (selectedCustomer === "-1") {
      alert("신규고객등록 부터 처리해주세요.");
      return;
    }

    if (customerGetDetail.isLoading) {
      alert("잠시만 기다려주세요.");
      return;
    }

    // customerGetDetail.mutate(data);
    setSelectedCustomer(data);
  };

  const btn_tapClose = async (data) => {
    if (selectedCustomer === "-1") {
      alert("신규고객등록 부터 처리해주세요.");
      return;
    }

    if (selectedCustomer === data.seqNo) {
      if (tapList.length === 1) {
        setSelectedCustomer("0");
        setTapList([]);
        await setMainData(customerManage_mainDataInit_atom);
      } else {
        if (tapList[tapList.length - 1].seqNo === data.seqNo) {
          setSelectedCustomer(tapList[tapList.length - 2].seqNo);
          setTapList(tapList.slice(0, -1));
        } else {
          setSelectedCustomer(tapList[tapList.findIndex(el => el.seqNo === data.seqNo) + 1].seqNo);
          setTapList(tapList.filter(el => el.seqNo !== data.seqNo));
        }
      }
    } else {
      setTapList(tapList.filter(el => el.seqNo !== data.seqNo));
    }
  };

  // 중복체크 버튼
  const btn_DuplicateCheck = () => {
    if (window.confirm("중복체크를 하시겠습니까?")) {
      customerCheckDupl.mutate({
        cellular: mainData.cellular,
        custId: mainData.custId,
        egubun: mainData.egubun,
        email: mainData.email,
        juminno: `${mainData?.juminno1 ? mainData.juminno1 : ""}${
          mainData?.juminno2 ? mainData.juminno2 : ""
        }`,
        ownerList: mainData.ownerList.map(el2 => ({
          celTel: el2.ownerCelTel,
          egubun: el2.ownerEGubun,
          email: el2.ownerEMail,
          juminno: `${el2?.ownerJuminno1 ? el2.ownerJuminno1 : ""}${
            el2?.ownerJuminno2 ? el2.ownerJuminno2 : ""
          }`,
        })),
      });
    }
  };

  // 취소 버튼
  const btn_cancel = async () => {
    if (selectedCustomer === "-1") {
      if (window.confirm("신규 등록을 취소하시겠습니까?")) {
        if (tapList.length === 1) {
          setSelectedCustomer("0");
          setTapList([]);
          await setMainData(customerManage_mainDataInit_atom);
        } else {
          setSelectedCustomer(tapList[1].seqNo);
          setTapList(prev => {
            const temp = prev.filter(el => el.seqNo !== "-1");
            return temp;
          });
        }

        setIsDuplicateCheck(false);
      }
    } else {
      if (window.confirm("수정을 취소하시겠습니까?")) {
        customerGetDetail.mutate(selectedCustomer);
      }
    }
  };

  // 담당자 추가
  const btn_ownerADD = () => {
    if (mainData.ownerList.length < 4) {
      setMainData({
        ...mainData,
        ownerList: [
          ...mainData.ownerList,
          {
            ownerCTel: "",
            ownerCelTel: "",
            ownerDevision: "",
            ownerEGubun: "99",
            ownerEMail: "",
            ownerJuminno: "",
            ownerName: "",
            ownerPosition: "",
            ownerRelation: "",
            ownerRemark: "",
            seqNo: null,
          },
        ],
      });
    } else {
      alert("담당자는 최대 4명 입니다.");
    }
  };

  // 담당자 삭제
  const btn_owner_delete = index => {
    setMainData({
      ...mainData,
      ownerList: mainData.ownerList.filter((el, index2) => index !== index2),
    });
  };

  const btn_sideMenu_click = index => {
    if (index === 0 && !sideMenuIsOpen[0]) {
      customerCheckMessage.mutate(
        messageList.list.map(el => ({
          currDate: el.currDate,
          ilNo: el.ilNo,
          ilNoSeq: el.ilNoSeq,
          userid: el.userid,
        }))
      );
    }

    // if (index === 3) {
    //   if (tapList.length === 0) {
    //     alert("고객을 선택해주세요.");
    //     return;
    //   }
    // }

    const temp = [...sideMenuIsOpen];
    temp[index] = !temp[index];
    setSideMenuIsOpen(temp);
  };

  const btn_side_custSearch = () => {
    customerSearchEmp.mutate(sideCustSearchData);
  };

  const btn_side_orderSearch = () => {
    customerSearchOrder.mutate(sideOrderSearchData);
  };

  const func_disabled_partner = () => {
    const userPermit = decrypt("userInfo").userPermit;
    const userJob = decrypt("userInfo").job;

    if (
      (userPermit === "61" && userJob === "61") ||
      userPermit === "51" || // 업무지원팀 51
      userJob === "51" || // 업무지원팀 51
      ((userPermit === "99" || userPermit === "91") && userJob === "91")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const pressEnter1 = e => {
    if (e.key === "Enter") {
      btn_side_custSearch();
    }
  };

  const pressEnter2 = e => {
    if (e.key === "Enter") {
      btn_side_orderSearch();
    }
  };

  const genPartnerOptions = () => {
    const userInfo = decrypt("userInfo");
    const isUserPermit99 = userInfo.userPermit === "99";
    const partnerData = isUserPermit99
      ? [...new Set([...(getPartner.data ?? []), ...(getEmptyPartner.data ?? [])])]
      : (getPartner.data ?? []);
    const isPartnerInData = partnerData?.find(el => el.key === mainData.partner) !== undefined;
    return (
      <>
        {isPartnerInData ? (
          <option value="" disabled={true} selected={true}>
            담당딜러 선택
          </option>
        ) : (
          <option value={mainData.partner} disabled={true} selected={true}>
            {mainData.partner}
          </option>
        )}
        {partnerData?.map(el => (
          <option value={el.key}>{el.value}</option>
        ))}
      </>
    );
  };

  return (
    <>
      {isDuplicateCheckModalOpen.isOpen && (
        <DuplicateCheckModal
          isDuplicateCheckModalOpen={isDuplicateCheckModalOpen}
          setIsDuplicateCheckModalOpen={setIsDuplicateCheckModalOpen}
        />
      )}

      {(isMainDataDeleteModalOpen.isOpen || isMainDataRegisterModalOpen.isOpen) && (
        <ReasonChangeRequestModal
          isMainDataDeleteModalOpen={isMainDataDeleteModalOpen}
          setIsMainDataDeleteModalOpen={setIsMainDataDeleteModalOpen}
          isMainDataRegisterModalOpen={isMainDataRegisterModalOpen}
          setIsMainDataRegisterModalOpen={setIsMainDataRegisterModalOpen}
          btn_search={btn_search}
          btn_search_treeNode={btn_search_treeNode}
          customerGetDetail={customerGetDetail}
        />
      )}

      {isSideMessageModalOpen.isOpen && (
        <SideMessageModal
          isSideMessageModalOpen={isSideMessageModalOpen}
          setIsSideMessageModalOpen={setIsSideMessageModalOpen}
        />
      )}

      {isSideGolfIssueModalOpen.isOpen && (
        <SideGolfIssueModal
          isSideGolfIssueModalOpen={isSideGolfIssueModalOpen}
          setIsSideGolfIssueModalOpen={setIsSideGolfIssueModalOpen}
        />
      )}

      {isSideSearchOrderModalOpen.isOpen && (
        <SideSearchOrderModal
          isSideSearchOrderModalOpen={isSideSearchOrderModalOpen}
          setIsSideSearchOrderModalOpen={setIsSideSearchOrderModalOpen}
        />
      )}

      <div className="tabs-main-heading">{tapList?.length !== 0 && <div>고객 정보</div>}</div>

      <div className="table-tabs-container">
        <div className="tabs-wrapper w-side-menu">
          {tapList.length > 0 && (
            <Tabs
              className="tabs-rectangle"
              id="controlled-tab-example"
              activeKey={selectedCustomer}
              onSelect={el => btn_tapClick(el)}
            >
              {tapList?.map((el, index) => {
                return (
                  <Tab
                    eventKey={el.seqNo}
                    title={
                      <>
                        {el.kname !== "" ? el.kname : el.company !== "" ? el.company : el.custId}
                        {el.seqNo !== "-1" && (
                          <span
                            className="btn-tab-close"
                            onClick={e => {
                              e.stopPropagation();
                              btn_tapClose(el);
                            }}
                          />
                        )}
                      </>
                    }
                  >
                    {el.seqNo === mainData.seqNo && (
                      <>
                        {/* 고객 메인정보 */}
                        <div className="tab-content-container">
                          <div className="tab1-row1 mb-2">
                            <div className="d-flex align-items-center">
                              <div className="tab1-row1-count me-4">
                                관리번호: {mainData?.custId.replace(/(.{4})/, "$1-")}
                              </div>
                              <div className="tab1-row1-field1">
                                <label style={{ marginLeft: "0" }}>분류 :</label>
                                <select
                                  className="form-select tab1-row1-form-select"
                                  value={mainData?.kindGubn}
                                  onChange={e => {

                                    setMainData({
                                      ...mainData,
                                      kindGubn: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="">분류 선택1</option>
                                  <option value="A">전화거부</option>
                                  <option value="B">연락처상이</option>
                                  <option value="C">기타사유</option>
                                </select>
                                <select
                                  className="form-select tab1-row1-form-select"
                                  value={mainData?.moveHope}
                                  onChange={e => {
                                    setMainData({
                                      ...mainData,
                                      moveHope: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="">분류 선택2</option>
                                  <option value="Y">이관요청</option>
                                  <option value="N">-</option>
                                </select>

                                {mainData.outCheck === "Y" && (
                                  <div style={{ color: "red", textDecoration: "line-through" }}>
                                    제명고객
                                  </div>
                                )}

                                <div className="ms-2">{`${mainData.exchangeCnt} 건거래 (${mainData.exchangeMine})건`}</div>
                                {mainData.donotcallChk === "Y" && (
                                  <div
                                    className={"ms-2"}
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    전화/문자거부(두낫콜)!!!
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <button
                                className="btn btn-outline-primary tab1-row1-btn"
                                onClick={btn_DuplicateCheck}
                              >
                                중복체크
                              </button>

                              {selectedCustomer !== "-1" &&
                                (decrypt("userInfo").userPermit === "99" ? (
                                  mainData.outCheck === "Y" ? (
                                    <button
                                      className="btn btn-outline-danger tab1-row1-btn"
                                      onClick={() => {
                                        setIsMainDataDeleteModalOpen({
                                          data: {
                                            text: "제명복구",
                                          },
                                          isOpen: true,
                                        });
                                      }}
                                    >
                                      제명복구
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-outline-danger tab1-row1-btn"
                                      onClick={() => {
                                        setIsMainDataDeleteModalOpen({
                                          data: {
                                            text: "제명",
                                          },
                                          isOpen: true,
                                        });
                                      }}
                                    >
                                      제명
                                    </button>
                                  )
                                ) : mainData.outCheck === "Y" ? (
                                  <button
                                    className="btn btn-outline-danger tab1-row1-btn"
                                    onClick={() => {
                                      setIsMainDataDeleteModalOpen({
                                        data: {
                                          text: "제명복구 요청",
                                        },
                                        isOpen: true,
                                      });
                                    }}
                                  >
                                    제명복구 요청
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-outline-danger tab1-row1-btn"
                                    onClick={() => {
                                      setIsMainDataDeleteModalOpen({
                                        data: {
                                          text: "제명요청",
                                        },
                                        isOpen: true,
                                      });
                                    }}
                                  >
                                    제명요청
                                  </button>
                                ))}

                              <button
                                className="btn btn-outline-primary tab1-row1-btn"
                                onClick={() => {
                                  if (selectedCustomer === "-1" && !isDuplicateCheck) {
                                    alert("중복체크를 해주세요.");
                                    return;
                                  }

                                  setIsMainDataRegisterModalOpen({
                                    data: {
                                      text: selectedCustomer !== "-1" ? "수정" : "신규저장",
                                    },
                                    isOpen: true,
                                  });
                                }}
                              >
                                저장
                              </button>
                              <button
                                className="btn btn-outline-primary tab1-row1-btn"
                                onClick={btn_cancel}
                              >
                                취소
                              </button>
                            </div>
                          </div>
                          {/* 고객정보 고객정보 */}
                          <table className="table table-bordered table-responsive my-table1">
                            <tbody>
                              <tr>
                                <th style={{ width: "8.052%" }}>특이사항</th>
                                <td style={{ width: "25.281%" }}>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.bigoRemk ?? ""}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        bigoRemk: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <th style={{ width: "8.052%" }}>고객분류</th>
                                <td style={{ width: "25.281%" }}>
                                  <div className="flex-center-between">
                                    <select
                                      className="form-select table-select table-select-inline float-start"
                                      value={mainData?.gubun}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          gubun: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" disabled={true} selected={true}>
                                        고객분류1 선택
                                      </option>
                                      {getCustGubun?.data?.map(el => (
                                        <option value={el.key}>{el.value}</option>
                                      ))}
                                    </select>
                                    <select
                                      className="form-select table-select table-select-inline float-end"
                                      value={mainData?.vipCheck}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          vipCheck: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" disabled={true} selected={true}>
                                        고객분류2 선택
                                      </option>
                                      {getVip.data?.map(el => (
                                        <option value={el.key}>{el.value}</option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <th style={{ width: "8.052%" }}>업체명</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.company ?? ""}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        company: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>성명</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.kname ?? ""}
                                    readOnly={
                                      mainData.exchangeCnt != 0 &&
                                      decrypt("userInfo").userPermit != 99 &&
                                      decrypt("userInfo").userPermit != 91
                                    }
                                    onChange={e =>
                                      setMainData({
                                        ...mainData,
                                        kname: e.target.value,
                                      })
                                    }
                                  />
                                </td>
                                <th>주민번호</th>
                                <td>
                                  <div className="flex-center-between">
                                    <input
                                      type="text"
                                      className="form-control table-input table-input-inline"
                                      value={mainData?.juminno1}
                                      onChange={e => {
                                        if (regex_only_number.test(e.target.value)) {
                                          setMainData({
                                            ...mainData,
                                            juminno1: e.target.value,
                                          });

                                          setIsDuplicateCheck(false);
                                        }
                                      }}
                                      maxLength={6}
                                    />
                                    <span className="txt_dash">-</span>
                                    <input
                                      type="text"
                                      className="form-control table-input table-input-inline"
                                      value={mainData.juminno2Temp}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          juminno2: e.target.value,
                                          juminno2Temp: e.target.value,
                                        });

                                        setIsDuplicateCheck(false);
                                      }}
                                      onFocus={() => {
                                        setMainData({
                                          ...mainData,
                                          juminno2Temp: mainData.juminno2,
                                        });
                                      }}
                                      onBlur={() => {
                                        setMainData({
                                          ...mainData,
                                          juminno2Temp:
                                            mainData.juminno2.slice(0, 2) +
                                            mainData.juminno2.slice(2, 7).replace(/[0-9]/g, "*"),
                                        });
                                      }}
                                      maxLength={7}
                                    />
                                  </div>
                                </td>
                                <th>교환</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.ctelChange ?? ""}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        ctelChange: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>직장전화</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.ctel}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        ctel: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <th className="f-roboto-medium">FAX</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.cfax}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        cfax: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <th>부서명</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.devision ?? ""}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        devision: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>자택전화</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.htel}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        htel: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <th>직위</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.position ?? ""}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        position: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <th>이메일</th>
                                <td>
                                  <div className="flex-center-between">
                                    <input
                                      type="text"
                                      className="form-control table-input table1-email1 table-input-inline"
                                      value={mainData?.email ?? ""}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          email: e.target.value,
                                        });

                                        setIsDuplicateCheck(false);
                                      }}
                                    />
                                    {mainData?.egubun !== "99" && <span className="txt_at">@</span>}
                                    <select
                                      className="form-select table1-email2 table-select-inline"
                                      value={mainData?.egubun ?? "99"}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          egubun: e.target.value,
                                        });

                                        setIsDuplicateCheck(false);
                                      }}
                                    >
                                      {getEmail?.data?.map(el => {
                                        return <option value={el.key}>{el.value}</option>;
                                      })}
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>휴대폰</th>
                                <td>
                                  <div className="d-flex">
                                    <input
                                      type="text"
                                      className="form-control table-input"
                                      value={mainData?.cellular}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          cellular: e.target.value,
                                        });

                                        setIsDuplicateCheck(false);
                                      }}
                                    />
                                    <button
                                      className="btn btn-outline-primary ms-3px"
                                      onClick={() => {
                                        const cell = oldToNewPhoneFormat(mainData.cellular);
                                        if (cell)
                                          setMainData({
                                            ...mainData,
                                            cellular: cell,
                                          });
                                      }}
                                    >
                                      국번전환
                                    </button>
                                  </div>
                                </td>
                                <th>계좌번호</th>
                                <td>
                                  <select
                                    className="form-select table-select table-select-inline float-start"
                                    value={mainData?.payBank ?? ""}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        payBank: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="" disabled={true} selected={true}>
                                      계좌번호 선택
                                    </option>
                                    {getBank?.data?.map(el => {
                                      return <option value={el.key}>{el.value}</option>;
                                    })}
                                  </select>
                                  <input
                                    type="text"
                                    className="form-control table-input table-input-inline float-end"
                                    value={mainData?.payAccount ?? ""}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        payAccount: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                                <th>예금주</th>
                                <td>
                                  <div className="flex-center-between">
                                    <input
                                      type="text"
                                      className="form-control table-input w-50"
                                      value={mainData?.payName ?? ""}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          payName: e.target.value,
                                        });
                                      }}
                                    />
                                    <select className="form-select filter1-select1 w-50 ms-8px">
                                      <option>지속관리</option>
                                      <option>전화번호 오류(이관희망)</option>
                                      <option>전화거부고객(이관희망)</option>
                                      <option>이름없는 고객(이관희망)</option>
                                      <option>장기 미관리(이관희망)</option>
                                      <option>폐기대상</option>
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>舊담당</th>
                                <td>
                                  {selectedCustomer === "-1" ? (
                                    <select
                                      className="form-select table-select"
                                      value={mainData.oldPartner}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          oldPartner: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="">舊담당 선택</option>
                                      {getUserId.data?.map(el => (
                                        <option value={el.key}>{el.value}</option>
                                      ))}
                                    </select>
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control table-input"
                                      value={
                                        getUserId.data?.find(el => el.key === mainData?.oldPartner)
                                          ?.value
                                      }
                                      readOnly={true}
                                    />
                                  )}
                                </td>
                                <th>담당딜러</th>
                                <td>
                                  <div className="flex-center-between">
                                    {/*<select*/}
                                    {/*  className="form-select table-select table-select-inline float-start"*/}
                                    {/*  value={mainData?.partner}*/}
                                    {/*  onChange={e => {*/}
                                    {/*    setMainData({*/}
                                    {/*      ...mainData,*/}
                                    {/*      partner: e.target.value,*/}
                                    {/*    });*/}
                                    {/*  }}*/}
                                    {/*  // disabled={decrypt("userInfo").userPermit !== "99"}*/}
                                    {/*  disabled={func_disabled_partner()}*/}
                                    {/*>*/}
                                    {/*  {genPartnerOptions()}*/}
                                    {/*</select>*/}
                                    <PartnerSelect
                                      data={mainData}
                                      keyName={"partner"}
                                      onChangeEventHandler={(key, e) => {
                                        setMainData({
                                          ...mainData,
                                          partner: e.value,
                                          jisa: e.jisa,
                                        });
                                      }}
                                      isDisabled={func_disabled_partner()}
                                      readonlyLevel={1}
                                      rangeLevel={lockAuth() - 1}
                                      locked={1}
                                    />

                                    <input
                                      type="text"
                                      className="form-control table-input table-input-inline float-end"
                                      value={
                                        getPartner.data?.find(el2 => el2.key === mainData.partner)
                                          ?.jisaName
                                      }
                                      readOnly={true}
                                    />
                                  </div>
                                </td>
                                <th>홈페이지</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.siteUrl ?? ""}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        siteUrl: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>업종</th>
                                <td>
                                  <select
                                    className="form-select table-select"
                                    value={mainData?.upteCode}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        upteCode: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="" disabled={true} selected={true}>
                                      업종 선택
                                    </option>
                                    {getUp?.data?.map(el => {
                                      return <option value={el.key}>{el.value}</option>;
                                    })}
                                  </select>
                                </td>
                                <th>다골프</th>
                                <td>
                                  <select
                                    className="form-select"
                                    value={mainData?.dagolfMember ?? ""}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        dagolfMember: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="" disabled={true} selected={true}>
                                      다골프 선택
                                    </option>
                                    <option value="A">신규고객</option>
                                    <option value="B">기존고객</option>
                                    <option value="C">계약해지</option>
                                    <option value="N">미보유</option>
                                  </select>
                                </td>
                                <th>비고</th>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control table-input"
                                    value={mainData?.remark ?? ""}
                                    onChange={e => {
                                      setMainData({
                                        ...mainData,
                                        remark: e.target.value,
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* 고객정보 담당자 */}
                          <div
                            className={`d-flex justify-content-between align-items-center ${
                              mainData.ownerList.length !== 0 ? "mb-2" : "mb-4"
                            }`}
                          >
                            <div className="table-tabs-sub mb-0">담당자</div>
                            <button
                              className="btn btn-outline-primary btn-tbl-add"
                              onClick={btn_ownerADD}
                            >
                              추가
                            </button>
                          </div>
                          {mainData.ownerList?.map((el, index) => {
                            return (
                              <>
                                <table className="table table-bordered table-responsive my-table1 mb-20">
                                  <colgroup>
                                    <col width="5%" />
                                    <col width="12%" />
                                    <col width="5%" />
                                    <col width="12%" />
                                    <col width="5%" />
                                    <col width="12%" />
                                    <col width="5%" />
                                    <col width="12%" />
                                  </colgroup>
                                  <tbody>
                                    {mainData.ownerList.length !== 0 ? (
                                      <>
                                        <tr>
                                          <td colSpan={8}>
                                            <div className="d-flex justify-content-between">
                                              <div>담당자 {index + 1}</div>
                                              <div
                                                className="tab-close me-3"
                                                onClick={() => btn_owner_delete(index)}
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>담당자</th>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control table-input"
                                              value={el?.ownerName ?? ""}
                                              onChange={e => {
                                                setMainData({
                                                  ...mainData,
                                                  ownerList: mainData.ownerList.map(
                                                    (el2, index2) => {
                                                      if (index === index2) {
                                                        return {
                                                          ...el2,
                                                          ownerName: e.target.value,
                                                        };
                                                      } else {
                                                        return el2;
                                                      }
                                                    }
                                                  ),
                                                });
                                              }}
                                            />
                                          </td>
                                          <th>직위</th>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control table-input"
                                              value={el?.ownerPosition ?? ""}
                                              onChange={e => {
                                                setMainData({
                                                  ...mainData,
                                                  ownerList: mainData.ownerList.map(
                                                    (el2, index2) => {
                                                      if (index === index2) {
                                                        return {
                                                          ...el2,
                                                          ownerPosition: e.target.value,
                                                        };
                                                      } else {
                                                        return el2;
                                                      }
                                                    }
                                                  ),
                                                });
                                              }}
                                            />
                                          </td>
                                          <th>고객과의 관계</th>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control table-input"
                                              value={el?.ownerRelation ?? ""}
                                              onChange={e => {
                                                setMainData({
                                                  ...mainData,
                                                  ownerList: mainData.ownerList.map(
                                                    (el2, index2) => {
                                                      if (index === index2) {
                                                        return {
                                                          ...el2,
                                                          ownerRelation: e.target.value,
                                                        };
                                                      } else {
                                                        return el2;
                                                      }
                                                    }
                                                  ),
                                                });
                                              }}
                                            />
                                          </td>
                                          <th>휴대폰</th>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control table-input"
                                              value={el?.ownerCelTel}
                                              onChange={e => {
                                                setMainData({
                                                  ...mainData,
                                                  ownerList: mainData.ownerList.map(
                                                    (el2, index2) => {
                                                      if (index === index2) {
                                                        return {
                                                          ...el2,
                                                          ownerCelTel: e.target.value,
                                                        };
                                                      } else {
                                                        return el2;
                                                      }
                                                    }
                                                  ),
                                                });

                                                setIsDuplicateCheck(false);
                                              }}
                                            />
                                            <button
                                              className="btn btn-outline-primary ms-3px"
                                              onClick={() => {
                                                const cell = oldToNewPhoneFormat(el.ownerCelTel);
                                                if (cell)
                                                  setMainData({
                                                    ...mainData,
                                                    ownerList: mainData.ownerList.map(
                                                      (el2, index2) =>
                                                        index === index2
                                                          ? { ...el2, ownerCelTel: cell }
                                                          : el2
                                                    ),
                                                  });
                                              }}
                                            >
                                              국번전환
                                            </button>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>부서</th>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control table-input"
                                              value={el?.ownerDevision ?? ""}
                                              onChange={e => {
                                                setMainData({
                                                  ...mainData,
                                                  ownerList: mainData.ownerList.map(
                                                    (el2, index2) => {
                                                      if (index === index2) {
                                                        return {
                                                          ...el2,
                                                          ownerDevision: e.target.value,
                                                        };
                                                      } else {
                                                        return el2;
                                                      }
                                                    }
                                                  ),
                                                });
                                              }}
                                            />
                                          </td>
                                          <th className="f-roboto-medium">E-MAIL</th>
                                          <td>
                                            <div className="flex-center-between">
                                              <input
                                                type="text"
                                                className="form-control table-input table1-email1 small table-input-inline float-start"
                                                value={el?.ownerEMail ?? ""}
                                                onChange={e => {
                                                  setMainData({
                                                    ...mainData,
                                                    ownerList: mainData.ownerList.map(
                                                      (el2, index2) => {
                                                        if (index === index2) {
                                                          return {
                                                            ...el2,
                                                            ownerEMail: e.target.value,
                                                          };
                                                        } else {
                                                          return el2;
                                                        }
                                                      }
                                                    ),
                                                  });

                                                  setIsDuplicateCheck(false);
                                                }}
                                              />
                                              {el?.ownerEGubun !== "99" && (
                                                <span className="txt_at">@</span>
                                              )}
                                              <select
                                                className="form-select table1-email2 small table-select table-select-inline float-end"
                                                value={el?.ownerEGubun ?? "99"}
                                                onChange={e => {
                                                  setMainData({
                                                    ...mainData,
                                                    ownerList: mainData.ownerList.map(
                                                      (el2, index2) => {
                                                        if (index === index2) {
                                                          return {
                                                            ...el2,
                                                            ownerEGubun: e.target.value,
                                                          };
                                                        } else {
                                                          return el2;
                                                        }
                                                      }
                                                    ),
                                                  });

                                                  setIsDuplicateCheck(false);
                                                }}
                                              >
                                                {getEmail.data?.map(el => {
                                                  return <option value={el.key}>{el.value}</option>;
                                                })}
                                              </select>
                                            </div>
                                          </td>
                                          <th>주민번호</th>
                                          <td>
                                            <div className="d-flex">
                                              <input
                                                type="text"
                                                className="form-control table-input table-input-inline"
                                                value={el?.ownerJuminno1 ?? ""}
                                                onChange={e => {
                                                  if (regex_only_number.test(e.target.value)) {
                                                    setMainData({
                                                      ...mainData,
                                                      ownerList: mainData.ownerList.map(
                                                        (el2, index2) => {
                                                          if (index === index2) {
                                                            return {
                                                              ...el2,
                                                              ownerJuminno1: e.target.value,
                                                            };
                                                          } else {
                                                            return el2;
                                                          }
                                                        }
                                                      ),
                                                    });

                                                    setIsDuplicateCheck(false);
                                                  }
                                                }}
                                                maxLength={6}
                                              />
                                              <span className="txt_dash">-</span>
                                              <input
                                                type="text"
                                                className="form-control table-input table-input-inline"
                                                value={el.ownerJuminno2Temp}
                                                onChange={e => {
                                                  setMainData({
                                                    ...mainData,
                                                    ownerList: mainData.ownerList.map(
                                                      (el2, index2) => {
                                                        if (index === index2) {
                                                          return {
                                                            ...el2,
                                                            ownerJuminno2: e.target.value,
                                                            ownerJuminno2Temp: e.target.value,
                                                          };
                                                        } else {
                                                          return el2;
                                                        }
                                                      }
                                                    ),
                                                  });
                                                }}
                                                onFocus={() => {
                                                  setMainData({
                                                    ...mainData,
                                                    ownerList: mainData.ownerList.map(
                                                      (el2, index2) => {
                                                        if (index === index2) {
                                                          return {
                                                            ...el2,
                                                            ownerJuminno2Temp: el2.ownerJuminno2,
                                                          };
                                                        } else {
                                                          return el2;
                                                        }
                                                      }
                                                    ),
                                                  });
                                                }}
                                                onBlur={() => {
                                                  setMainData({
                                                    ...mainData,
                                                    ownerList: mainData.ownerList.map(
                                                      (el2, index2) => {
                                                        if (index === index2) {
                                                          return {
                                                            ...el2,
                                                            ownerJuminno2Temp:
                                                              (el2.ownerJuminno2 ?? "").length > 2
                                                                ? el2.ownerJuminno2.slice(0, 2) +
                                                                  el2.ownerJuminno2
                                                                    .slice(2, 7)
                                                                    .replace(/[0-9]/g, "*")
                                                                : el2.ownerJuminno2,
                                                          };
                                                        } else {
                                                          return el2;
                                                        }
                                                      }
                                                    ),
                                                  });
                                                }}
                                                maxLength={7}
                                              />
                                            </div>
                                          </td>
                                          <th>유선전화</th>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control table-input"
                                              value={el?.ownerCTel}
                                              onChange={e => {
                                                setMainData({
                                                  ...mainData,
                                                  ownerList: mainData.ownerList.map(
                                                    (el2, index2) => {
                                                      if (index === index2) {
                                                        return {
                                                          ...el2,
                                                          ownerCTel: e.target.value,
                                                        };
                                                      } else {
                                                        return el2;
                                                      }
                                                    }
                                                  ),
                                                });
                                              }}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>비고</th>
                                          <td colSpan={7}>
                                            <input
                                              type="text"
                                              className="form-control table-input"
                                              value={el?.ownerRemark ?? ""}
                                              onChange={e => {
                                                setMainData({
                                                  ...mainData,
                                                  ownerList: mainData.ownerList.map(
                                                    (el2, index2) => {
                                                      if (index === index2) {
                                                        return {
                                                          ...el2,
                                                          ownerRemark: e.target.value,
                                                        };
                                                      } else {
                                                        return el2;
                                                      }
                                                    }
                                                  ),
                                                });
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    ) : (
                                      <tr>
                                        <td>담당자가 없습니다.</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </>
                            );
                          })}

                          {/* 고객정보 직장주소 */}
                          <div
                            className={`cursor-pointer d-flex justify-content-between align-items-center ${
                              addressIsOpen[0] ? "mb-2" : "mb-4"
                            }`}
                            onClick={() => {
                              setAddressIsOpen(prev => [!prev[0], prev[1]]);
                            }}
                          >
                            <div className="table-tabs-sub mb-0">직장주소</div>
                            <div className="link-expand">
                              {addressIsOpen[0] ? "접기" : "펼치기"}
                              <img
                                className={`ms-2 ${addressIsOpen[0] && "active"}`}
                                src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                                alt=""
                              />
                            </div>
                          </div>
                          {addressIsOpen[0] && (
                            <div className="address-table mt-2 mb-2">
                              <table className="table table-bordered table-responsive my-table1">
                                <tbody>
                                  <tr>
                                    <th style={{ width: "11.9%" }}>도로명주소</th>
                                    <td>
                                      <div className="flex-center-between">
                                        <input
                                          type="text"
                                          className="form-control table-input input-address1"
                                          value={mainData?.rcAreaCode}
                                        />
                                        <DaumPostcode
                                          onCompleteCallback={data =>
                                            setMainData({
                                              ...mainData,
                                              rcAreaCode: data.zonecode,
                                              rcZipcode: data.zonecode,
                                              rcBdcode: data.bdCode,
                                              rcAddresTxt: data.fullAddr,
                                              rcAddres: "",
                                            })
                                          }
                                        />
                                        <input
                                          type="text"
                                          className="form-control table-input input-address2 mx-3px"
                                          value={mainData?.rcAddresTxt}
                                        />
                                        <input
                                          type="text"
                                          className="form-control table-input input-address3"
                                          value={mainData?.rcAddres}
                                          onChange={e => {
                                            setMainData({
                                              ...mainData,
                                              rcAddres: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}

                          {/* 고객정보 자택주소 */}
                          <div
                            className={`cursor-pointer d-flex align-items-center justify-content-between ${
                              addressIsOpen[1] ? "mb-2" : "mb-4"
                            }`}
                            onClick={() => {
                              setAddressIsOpen(prev => [prev[0], !prev[1]]);
                            }}
                          >
                            <div className="table-tabs-sub mb-0">자택주소</div>
                            <div className="link-expand">
                              {addressIsOpen[1] ? "접기" : "펼치기"}
                              <img
                                className={`ms-2 ${addressIsOpen[1] && "active"}`}
                                src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                                alt=""
                              />
                            </div>
                          </div>
                          {addressIsOpen[1] && (
                            <div className="address-table mb-2">
                              <table className="table table-bordered table-responsive my-table1">
                                <tbody>
                                  <tr>
                                    <th style={{ width: "11.9%" }}>도로명주소</th>
                                    <td>
                                      <div className="flex-center-between">
                                        <input
                                          type="text"
                                          className="form-control table-input input-address1"
                                          value={mainData?.rhAreaCode ?? ""}
                                        />
                                        <DaumPostcode
                                          onCompleteCallback={data =>
                                            setMainData({
                                              ...mainData,
                                              rhAreaCode: data.zonecode,
                                              rhZipcode: data.zonecode,
                                              rhBdcode: data.bdCode,
                                              rhAddresTxt: data.fullAddr,
                                              rhAddres: "",
                                            })
                                          }
                                        />
                                        <input
                                          type="text"
                                          className="form-control table-input input-address2 mx-3px"
                                          value={mainData?.rhAddresTxt}
                                        />
                                        <input
                                          type="text"
                                          className="form-control table-input input-address3"
                                          value={mainData?.rhAddres}
                                          onChange={e => {
                                            setMainData({
                                              ...mainData,
                                              rhAddres: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}
                          {(mainData.memberKname !== "" ||
                            mainData.memberJuminno !== "" ||
                            mainData.memberAddress !== "") && (
                            <div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="table-tabs-sub mb-0">회원증</div>
                              </div>

                              <div className="mt-2">
                                <table className="table table-bordered table-responsive my-table1">
                                  <tbody>
                                    <tr>
                                      <th style={{ width: "12.078%" }}>성명</th>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control table-input table-input-short w-100"
                                          value={mainData?.memberKname ?? ""}
                                          onChange={e => {
                                            setMainData({
                                              ...mainData,
                                              memberKname: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                      <th style={{ width: "12.078%" }}>주민번호</th>
                                      <td>
                                        <div className="d-flex">
                                          <input
                                            type="text"
                                            className="form-control table-input table-input-inline"
                                            value={mainData?.memberJuminno1 ?? ""}
                                            onChange={e => {
                                              if (regex_only_number.test(e.target.value)) {
                                                setMainData({
                                                  ...mainData,
                                                  memberJuminno1: e.target.value,
                                                });
                                              }
                                            }}
                                            maxLength={6}
                                          />
                                          <span className="txt_dash">-</span>
                                          <input
                                            type="text"
                                            className="form-control table-input table-input-inline"
                                            // value={mainData?.memberJuminno2 ?? ""}
                                            // onChange={e => {
                                            //   if (regex_only_number.test(e.target.value)) {
                                            //     setMainData({
                                            //       ...mainData,
                                            //       memberJuminno2: e.target.value,
                                            //     });
                                            //   }
                                            // }}

                                            value={mainData.memberJuminno2Temp}
                                            onChange={e => {
                                              setMainData({
                                                ...mainData,
                                                memberJuminno2: e.target.value,
                                                memberJuminno2Temp: e.target.value,
                                              });
                                            }}
                                            onFocus={() => {
                                              setMainData({
                                                ...mainData,
                                                memberJuminno2Temp: mainData.memberJuminno2,
                                              });
                                            }}
                                            onBlur={() => {
                                              setMainData({
                                                ...mainData,
                                                memberJuminno2Temp:
                                                  mainData.memberJuminno2.slice(0, 2) +
                                                  mainData.memberJuminno2
                                                    .slice(2, 7)
                                                    .replace(/[0-9]/g, "*"),
                                              });
                                            }}
                                            maxLength={7}
                                          />
                                        </div>
                                      </td>
                                      <th style={{ width: "12.078%" }}>주소</th>
                                      <td style={{ width: "37.2%" }}>
                                        <input
                                          type="text"
                                          className="form-control table-input table-input-short w-100"
                                          value={mainData?.memberAddress ?? ""}
                                          onChange={e => {
                                            setMainData({
                                              ...mainData,
                                              memberAddress: e.target.value,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}

                          <div>
                            <div className="mb-2" style={{ borderTop: "1px solid #c5c5c5" }} />
                            <table className="table table-bordered table-responsive my-table1 mb-0">
                              <tbody>
                                <tr>
                                  <th style={{ width: "12.078%" }}>명단출처</th>
                                  <td style={{ width: "38.616%" }} colSpan={3}>
                                    <div className="flex-center-between">
                                      <select
                                        className="form-select table-select w-100"
                                        value={mainData?.dbCode}
                                        onChange={e => {
                                          setMainData({
                                            ...mainData,
                                            dbCode: e.target.value,
                                          });
                                        }}
                                      >
                                        <option value="" disabled={true} selected={true}>
                                          명단출처 선택
                                        </option>
                                        {getDb?.data?.map(el => (
                                          <option value={el.key}>{el.value}</option>
                                        ))}
                                      </select>
                                      {(mainData.dbCode === "9999" ||
                                        mainData.dbCode === "5004" ||
                                        mainData.dbCode === "9005") && (
                                        <input
                                          type="text"
                                          className="form-control table-input table-input-inline w-100 ms-8px"
                                          value={mainData?.dbReal}
                                          onChange={e => {
                                            setMainData({
                                              ...mainData,
                                              dbReal: e.target.value,
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <th style={{ width: "12.078%" }}>최초등록</th>
                                  <td style={{ width: "13.274%" }}>
                                    <input
                                      type="date"
                                      className="form-control table-input"
                                      value={mainData?.inDate}
                                      readOnly={true}
                                      max="9999-12-31"
                                    />
                                  </td>
                                  <th style={{ width: "12.078%" }}>문자전송</th>
                                  <td>
                                    <select
                                      className="form-select w-100"
                                      value={mainData?.hpCheck}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          hpCheck: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" disabled={true} selected={true}>
                                        문자전송 선택
                                      </option>
                                      <option value="Y">가능</option>
                                      <option value="N">거부</option>
                                    </select>
                                  </td>
                                </tr>
                                <tr>
                                  <th>전화응대</th>
                                  <td>
                                    <select
                                      className="form-select w-100"
                                      value={mainData?.telCheck}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          telCheck: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" disabled={true}>
                                        전화응대 선택
                                      </option>
                                      <option value="N">미지정</option>
                                      <option value="1">상(上)</option>
                                      <option value="2">중(中)</option>
                                      <option value="3">하(下)</option>
                                      <option value="D">거부</option>
                                    </select>
                                  </td>
                                  <th style={{ width: "12.078%" }}>회원권보유</th>
                                  <td>
                                    <select
                                      className="form-select w-100"
                                      value={mainData?.corpCheck}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          corpCheck: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" disabled={true} selected={true}>
                                        회원권보유
                                      </option>
                                      {getCorp?.data?.map(el => {
                                        return <option value={el.key}>{el.value}</option>;
                                      })}
                                    </select>
                                  </td>
                                  <th>주 휴대폰</th>
                                  <td>
                                    <select
                                      className="form-select w-100"
                                      value={mainData?.mainCellular}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          mainCellular: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="1">주고객</option>
                                      {Array.from(
                                        { length: mainData.ownerList.length },
                                        (_, idx) => (
                                          <option value={idx + 2}>담당자 {idx + 1}</option>
                                        )
                                      )}
                                    </select>
                                  </td>
                                  <th>광고문자</th>
                                  <td>
                                    <select
                                      className="form-select w-100"
                                      value={mainData?.hpCheck2}
                                      onChange={e => {
                                        setMainData({
                                          ...mainData,
                                          hpCheck2: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" disabled={true} selected={true}>
                                        광고문자 선택
                                      </option>
                                      <option value="Y">가능</option>
                                      <option value="N">거부</option>
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          {/* 고객정보 부정보 */}
                          <div>
                            {selectedCustomer !== "-1" && (
                              <Tabs
                                className="tab-blocks"
                                id="controlled-tab-example2"
                                activeKey={selectedSubCustomerData}
                                onSelect={k => setSelectedSubCustomerData(k)}
                              >
                                <Tab eventKey="통화내역" title="통화내역">
                                  {selectedSubCustomerData === "통화내역" && <CallHistory />}
                                </Tab>
                                <Tab eventKey="등기발송" title="등기발송">
                                  {selectedSubCustomerData === "등기발송" && <RegisteredDelivery />}
                                </Tab>
                                <Tab eventKey="SMS 전송내역" title="SMS 전송내역">
                                  {selectedSubCustomerData === "SMS 전송내역" && <SMSHistory />}
                                </Tab>
                                <Tab eventKey="거래내역" title="거래내역">
                                  {selectedSubCustomerData === "거래내역" && <TransactionHistory />}
                                </Tab>
                                <Tab eventKey="주문관리" title="주문관리">
                                  {selectedSubCustomerData === "주문관리" && <OrderManagement />}
                                </Tab>
                                <Tab eventKey="Old 통화" title="Old 통화">
                                  {selectedSubCustomerData === "Old 통화" && <OldCall />}
                                </Tab>
                                <Tab eventKey="Old 문자" title="Old 문자">
                                  {selectedSubCustomerData === "Old 문자" && <OldSMS />}
                                </Tab>
                                <Tab eventKey="사업자등록증" title="사업자등록증">
                                  {selectedSubCustomerData === "사업자등록증" && (
                                    <BusinessRegistration />
                                  )}
                                </Tab>
                                <Tab eventKey="세금계산서요청" title="세금계산서요청">
                                  {selectedSubCustomerData === "세금계산서요청" && (
                                    <TaxCalcuationRequset />
                                  )}
                                </Tab>
                                <Tab eventKey="정보변동내역" title="정보변동내역">
                                  {selectedSubCustomerData === "정보변동내역" && (
                                    <InfomationChangeHistory />
                                  )}
                                </Tab>
                                <Tab eventKey="보유회원권" title="보유회원권">
                                  {selectedSubCustomerData === "보유회원권" && <MemberShip />}
                                </Tab>
                                {decrypt("userInfo")?.webOrder === "Y" && (
                                  <Tab eventKey="웹오더" title="웹오더">
                                    {selectedSubCustomerData === "웹오더" && <WebOrder />}
                                  </Tab>
                                )}
                              </Tabs>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </Tab>
                );
              })}
            </Tabs>
          )}
        </div>

        {/* 사이드 메뉴 */}
        <div className={`side-menu ${tapList?.length !== 0 && "mt-4"}`}>
          <ul>
            <li>
              <ul className="side-menu-wrap">
                <li className="side-menu-title" onClick={() => btn_sideMenu_click(0)}>
                  <div>
                    <span className="f-roboto-bold f-15">Message</span>
                  </div>
                  <div>
                    <span>
                      {messageList.newCount !== 0 && (
                        <Badge pill bg="danger">
                          {messageList.newCount}
                        </Badge>
                      )}

                      <img
                        className={`${sideMenuIsOpen[0] && "active"}`}
                        src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                        alt=""
                      />
                    </span>
                  </div>
                </li>
                <li
                  className={`side-menu-content ${sideMenuIsOpen[0] && "active"}`}
                  style={{ borderTop: "1px solid #c5c5c5" }}
                >
                  <div className="d-flex justify-content-end mt-1 mb-2">
                    <button
                      className="btn btn-primary btn-table-filter"
                      onClick={() => {
                        if (window.confirm("전달사항으로 이동하시겠습니까?")) {
                          // navigate(defaultURL + "/work/delivery_message");
                          sidebarOnClickEvent({
                            name: "전달사항",
                            url: "/work/info/deliveryMessage",
                          });
                        }
                      }}
                    >
                      등록
                    </button>
                  </div>

                  <div className={`overflow-auto table-container table-sticky`}>
                    <table
                      className="table table-bordered table-responsive my-table mt-0"
                      style={{ width: "410px" }}
                    >
                      <colgroup>
                        <col width="15%" />
                        <col width="20%" />
                        <col width="40%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>게시자</th>
                          <th>일시</th>
                          <th>제목</th>
                        </tr>
                      </thead>
                      <tbody>
                        {messageList.list?.length !== 0 ? (
                          messageList.list?.map((el, idx) => (
                            <tr
                              className="cursor-pointer"
                              onClick={() =>
                                setIsSideMessageModalOpen({
                                  data: el,
                                  isOpen: true,
                                })
                              }
                            >
                              <td>{idx + 1}</td>
                              <td>{getUserIdQ.data?.find(el2 => el2.key === el.userid)?.value}</td>
                              <td>
                                {el?.sendDate ? moment(el.sendDate).format("YYYY-MM-DD") : ""}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control table-input"
                                  value={el.title}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4}>데이터가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </li>

            <li>
              <ul className="side-menu-wrap">
                <li className="side-menu-title" onClick={() => btn_sideMenu_click(1)}>
                  <div>
                    <span className="f-bold f-15">골프장 특이사항</span>
                  </div>
                  <div>
                    <span>
                      <img
                        className={`${sideMenuIsOpen[1] && "active"}`}
                        src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                        alt=""
                      />
                    </span>
                  </div>
                </li>
                <li
                  className={`side-menu-content ${sideMenuIsOpen[1] && "active"}`}
                  style={{ borderTop: "1px solid #c5c5c5" }}
                >
                  <div className="overflow-auto table-container table-sticky">
                    <table
                      className="table table-bordered table-responsive my-table mt-0"
                      style={{ width: "800px" }}
                    >
                      <colgroup>
                        <col width="6%" />
                        <col width="12%" />
                        <col width="6%" />
                        <col width="6%" />
                        <col width="6%" />
                        <col width="12%" />
                        <col width="10%" />
                        <col width="12%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>골프장 명</th>
                          <th>구분</th>
                          <th>세부</th>
                          <th>통화</th>
                          <th>특이사항</th>
                          <th>수정자</th>
                          <th>수정일시</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerGetGolfIssue.data && customerGetGolfIssue.data?.length !== 0 ? (
                          customerGetGolfIssue.data.map((el, idx) => (
                            <tr
                              key={el.ilNo}
                              className={`cursor-pointer ${
                                isSideGolfIssueModalOpen.data?.ilNo === el.ilNo
                                  ? "bg-lightblue"
                                  : ""
                              }`}
                              onClick={() =>
                                setIsSideGolfIssueModalOpen({ isOpen: true, data: el })
                              }
                            >
                              <td>{idx + 1}</td>
                              <td>{el.company}</td>
                              <td>{el.viewChk === "1" ? "일반" : "필독"}</td>
                              <td>
                                {+el.detailFlag > 0 ? (
                                  <img src={process.env.PUBLIC_URL + "/images/detail.svg"} alt="" />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {+el.detailTalk > 0 ? (
                                  <img src={process.env.PUBLIC_URL + "/images/call.svg"} alt="" />
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>{el.remark ?? ""}</td>
                              <td>{el.user}</td>
                              <td>{moment(el.modDate).format("YYYY-MM-DD HH:mm:ss")}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>데이터가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </li>

            <li onClick={() => btn_sideMenu_click(2)}>
              <ul className="side-menu-wrap">
                <li className="side-menu-title">
                  <div>
                    <span className="f-bold f-15">직원조회</span>
                  </div>
                  <div>
                    <span>
                      <img
                        className={`${sideMenuIsOpen[2] && "active"}`}
                        src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                        alt=""
                      />
                    </span>
                  </div>
                </li>
                <li
                  className={`side-menu-content ${sideMenuIsOpen[2] && "active"}`}
                  onClick={e => e.stopPropagation()}
                  style={{ borderTop: "1px solid #c5c5c5" }}
                >
                  <div className="d-flex mt-1" onKeyDown={pressEnter1}>
                    <label className="filter1-label" style={{ whiteSpace: "nowrap" }}>
                      성명or내선번호
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={sideCustSearchData.partner}
                      onChange={e => {
                        setSideCustSearchData({ partner: e.target.value });
                      }}
                    />
                    <button
                      className="btn btn-outline-primary btn-filter-outline-primary m-0"
                      onClick={btn_side_custSearch}
                    >
                      조회
                    </button>
                  </div>
                  <div className="overflow-auto table-container table-sticky mt-2 h-183">
                    <table
                      className="table table-bordered table-responsive my-table table-fit mt-0"
                      // style={{
                      //   width: `${
                      //     customerSearchEmp.data && customerSearchEmp.data?.length !== 0
                      //       ? "800px"
                      //       : ""
                      //   }`,
                      // }}
                    >
                      <colgroup>
                        <col width="3%" />
                        <col width="3%" />
                        <col width="3%" />
                        <col width="3%" />
                        <col width="5%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>부서명</th>
                          <th>성명</th>
                          <th>직위</th>
                          <th>내선</th>
                          <th>휴대폰</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerSearchEmp.data && customerSearchEmp.data?.length !== 0 ? (
                          customerSearchEmp.data?.map(el => {
                            return (
                              <tr
                                className="cursor-pointer"
                                onClick={() =>
                                  setIsSendSMSModalOpen({
                                    data: [{ ...el, position: el.authName }],
                                    isOpen: true,
                                  })
                                }
                              >
                                <td>{el.jobName}</td>
                                <td>{el.kname}</td>
                                <td>{el.authName}</td>
                                <td>{el.ext1}</td>
                                <td>{el.cellular}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={5}>데이터가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </li>

            <li>
              <ul className="side-menu-wrap">
                <li className="side-menu-title" onClick={() => btn_sideMenu_click(3)}>
                  <div>
                    <span className="f-bold f-15">주문현황</span>
                  </div>
                  <div>
                    <span>
                      <img
                        className={`${sideMenuIsOpen[3] && "active"}`}
                        src={process.env.PUBLIC_URL + "/images/icon-arrow-dwn.png"}
                        alt=""
                      />
                    </span>
                  </div>
                </li>
                <li
                  className={`side-menu-content ${sideMenuIsOpen[3] && "active"}`}
                  style={{ borderTop: "1px solid #c5c5c5" }}
                >
                  <div onKeyDown={pressEnter2}>
                    <div className="d-flex">
                      <label className="filter1-label" style={{ whiteSpace: "nowrap" }}>
                        검색조건
                      </label>
                      <select
                        className="form-select"
                        value={sideOrderSearchData.gubun}
                        onChange={e => {
                          setSideOrderSearchData({
                            ...sideOrderSearchData,
                            gubun: e.target.value,
                          });
                        }}
                      >
                        <option value="">전체</option>
                        <option value="1">골프</option>
                        <option value="2">콘도</option>
                        <option value="3">헬스</option>
                      </select>
                    </div>
                    <div className="d-flex mt-1">
                      <input
                        type="date"
                        className="form-control"
                        value={sideOrderSearchData.date}
                        onChange={e => {
                          setSideOrderSearchData({
                            ...sideOrderSearchData,
                            date: e.target.value,
                          });
                        }}
                        max="9999-12-31"
                      />
                      <label className="filter1-label" style={{ whiteSpace: "nowrap" }}>
                        일 이후
                      </label>
                    </div>
                    <div className="d-flex mt-1">
                      <label className="filter1-label" style={{ whiteSpace: "nowrap" }}>
                        종목명
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={sideOrderSearchData.keyword}
                        onChange={e => {
                          setSideOrderSearchData({
                            ...sideOrderSearchData,
                            keyword: e.target.value,
                          });
                        }}
                        placeholder="검색어를 입력해주세요"
                      />
                      <button
                        className="btn btn-outline-primary btn-filter-outline-primary m-0"
                        onClick={btn_side_orderSearch}
                      >
                        조회
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <label className="filter1-label">개서 中, 샵거래, 취소, 종료 건 제외</label>
                  </div>
                  <div className="overflow-auto table-container table-sticky h-183 mt-2">
                    <table
                      className="table table-bordered table-responsive my-table mt-0"
                      style={{
                        width: `${
                          customerSearchOrder.data && customerSearchOrder.data?.length !== 0
                            ? "410px"
                            : ""
                        }`,
                      }}
                    >
                      <colgroup>
                        <col width="13%" />
                        <col width="48%" />
                        <col width="13%" />
                        <col width="13%" />
                        <col width="13%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>구분</th>
                          <th>회원권명</th>
                          <th>상태</th>
                          <th>매도</th>
                          <th>매수</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerSearchOrder.data && customerSearchOrder.data?.length !== 0 ? (
                          customerSearchOrder.data?.map(el => {
                            return (
                              <tr
                                className="cursor-pointer"
                                onClick={() =>
                                  setIsSideSearchOrderModalOpen({
                                    isOpen: true,
                                    data: { ...el, startDate: sideOrderSearchData.date },
                                  })
                                }
                              >
                                <td>
                                  {el.classCode === "GM"
                                    ? "골프"
                                    : el.classCode === "CM"
                                      ? "콘도"
                                      : "휘트니스"}
                                </td>
                                <td>{el.codename}</td>
                                <td>{el.procName}</td>
                                <td>{el.medoCnt}</td>
                                <td>{el.mesuCnt}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={5}>데이터가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
