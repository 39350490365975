import React, { useEffect, useMemo } from "react";
import { useAtom } from "jotai/index";
import {
  clickedRow_atom,
  transactionMain_atom,
  transactionSubList_atom,
} from "../../../data/atom/dataManage/transactionProgress/transactionProgress_atom";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../util/axios/axios";
import { useGetEstimateMiddleKind } from "../../../api/code/useGetEstimateMiddleKind";
import moment from "moment/moment";
import { useGetEtccodeDw } from "../../../api/code/useGetEtccodeDw";
import Select from "react-select-virtualized";

const TransactionProgressSub = () => {
  const [clickedRow, setClickedRow] = useAtom(clickedRow_atom);
  const [transactionMain, setTransactionMain] = useAtom(transactionMain_atom);
  const [transactionSubList, setTransactionSubList] = useAtom(transactionSubList_atom);
  const isAdd = transactionSubList.filter(el => el.newCheck).length !== 0;

  const etccodeGM = useGetEtccodeDw("GM");
  const etccodeCM = useGetEtccodeDw("CM");
  const etccodeSM = useGetEtccodeDw("SM");

  const GMOptions = useMemo(
    () => etccodeGM.data?.map(el => <option value={el.key}>{el.value}</option>),
    [etccodeGM.data]
  );
  const CMOptions = useMemo(
    () => etccodeCM.data?.map(el => <option value={el.key}>{el.value}</option>),
    [etccodeCM.data]
  );
  const SMOptions = useMemo(
    () => etccodeSM.data?.map(el => <option value={el.key}>{el.value}</option>),
    [etccodeSM.data]
  );

  const getEstimateMiddleKind = useGetEstimateMiddleKind();
  const searchTransactionSubList = useMutation(
    data =>
      axios.get("/api/data/transactionManage/middle/getDetail", {
        params: { seqnNumb: data.seqnNumb, tradSeqn: data.tradSeqn, tradDate: data.tradDate },
      }),
    {
      onSuccess: res => {
        const data = res.data.result;

        setTransactionSubList(data);
      },
    }
  );
  const deleteTransactionSubList = useMutation(
    data => axios.post("/api/data/transactionManage/middle/delete", data),
    {
      onSuccess: res => {
        searchTransactionSubList.mutate(transactionMain);
      },
    }
  );
  const saveTransactionSubList = useMutation(
    data => axios.post("/api/data/transactionManage/middle/save", data),
    {
      onSuccess: res => {
        searchTransactionSubList.mutate(transactionMain);
      },
    }
  );

  useEffect(() => {
    if (clickedRow.seqnNumb !== undefined) {
      searchTransactionSubList.mutate({ ...clickedRow, remark: "", submitDoc: "" });
    }
  }, [clickedRow]);

  const onChangeSubListEventHandler = async (key, value, idx) => {
    const setWriteAmnt = (kindFlag, writeAmnt) => {
      const writeAmntMap = {
        11: 1,
        12: 2,
        13: 3,
        14: 4,
        15: 5,
        21: 1,
        22: 2,
        23: 4,
        24: 6,
        25: 8,
        26: 10,
        35: 0,
        36: 0,
      };
      return (writeAmntMap[kindFlag] || 0) * writeAmnt;
    };

    const setStampAmnt = totalAmnt => {
      const editedValue = Number(totalAmnt.toString().replace(/,/g, ""));
      if (editedValue <= 10000000) return 0;
      if (editedValue <= 30000000) return 20000;
      if (editedValue <= 50000000) return 40000;
      if (editedValue <= 100000000) return 70000;
      if (editedValue <= 1000000000) return 150000;
      return 350000;
    };

    let newElout = { ...transactionSubList[idx], [key]: value };
    if (newElout.tradGubn === "S") {
      try {
        if (newElout.tradCode !== transactionSubList[idx].tradCode || key === "tradGubn") {
          const res = await axios.get(
            `/api/data/transactionManage/gaesu?code=${newElout.tradCode}`
          );
          newElout.writeAmnt = res.data?.result?.[0]?.writeAmnt || 0;
        }
      } catch {
        alert("서버 오류");
      }
    } else newElout.writeAmnt = 0;

    setTransactionSubList(prev =>
      prev.map((el, idx2) => {
        if (idx !== idx2) return el;

        const updateRemainAmnt = (el, key, value, wa, stampAmnt) => {
          return (
            +el.totalAmnt -
            +el.firstAmnt +
            (el.tradGubn === "S"
              ? +el.commiAmnt + +wa + +stampAmnt + +el.addedAmnt
              : -+el.commiAmnt)
          );
        };

        let newEl = { ...el, [key]: value };

        if (key === "tradGubn" && newEl.tradGubn === "S") {
          if (key === "tradGubn") {
            newEl.writeAmnt = setWriteAmnt(newEl.kindFlag, newElout.writeAmnt);
            newEl.stampAmnt = setStampAmnt(newEl.totalAmnt);
          } else if (key === "kindFlag") {
            newEl.writeAmnt = setWriteAmnt(value, newElout.writeAmnt);
          } else if (key === "totalAmnt") {
            newEl.stampAmnt = setStampAmnt(value);
          }
          newEl.remainAmnt = updateRemainAmnt(newEl, key, value, newEl.writeAmnt, newEl.stampAmnt);
        } else {
          if (
            key === "totalAmnt" ||
            key === "firstAmnt" ||
            key === "commiAmnt" ||
            key === "addedAmnt"
          ) {
            newEl.stampAmnt = setStampAmnt(0);
            newEl.writeAmnt = setWriteAmnt(0, 0);
            newEl.remainAmnt = updateRemainAmnt(newEl, key, value, 0, 0);
          } else if (key === "tradClass") {
            const found =
              value === "1"
                ? etccodeGM.data[0]
                : value === "2"
                ? etccodeCM.data[0]
                : etccodeSM.data[0];
            newEl.codeName = found.value;
            newEl.tradCode = found.key;
          } else if (key === "tradCode") {
            newEl.codeName =
              newEl.tradClass === "1"
                ? etccodeGM.data.find(el => el.key === value).value
                : newEl.tradClass === "2"
                ? etccodeCM.data.find(el => el.key === value).value
                : etccodeSM.data.find(el => el.key === value).value;
            newEl.writeAmnt = setWriteAmnt(newEl.kindFlag, newElout.writeAmnt);
            newEl.remainAmnt = updateRemainAmnt(
              newEl,
              key,
              value,
              newEl.writeAmnt,
              newEl.stampAmnt
            );
          } else {
            newEl.writeAmnt = setWriteAmnt(newEl.kindFlag, newElout.writeAmnt);
            newEl.remainAmnt = updateRemainAmnt(
              newEl,
              key,
              value,
              newEl.writeAmnt,
              newEl.stampAmnt
            );
          }
        }

        return newEl;
      })
    );
  };
  const btnAdd = () => {
    setTransactionSubList([
      {
        codeName: etccodeGM.data[0].value,
        addedAmnt: "0",
        commiAmnt: "0",
        firstAmnt: "0",
        stampAmnt: "0",
        totalAmnt: "0",
        writeAmnt: "0",
        remainAmnt: "0",
        gubnName: "정회원",
        kindFlag: "11",
        newCheck: true,
        seqnNumb: Number(transactionMain.seqnNumb),
        tradClass: "1",
        tradCode: etccodeGM.data[0].key,
        tradDate: moment(transactionMain.tradDate).format("YYYYMMDD"),
        tradGubn: "D",
        tradSeqn: transactionMain.tradSeqn,
      },
      ...transactionSubList,
    ]);
  };
  const btnSave = () => {
    if (confirm("저장하시겠습니까?")) {
      if (transactionSubList.length === 0) {
        alert("저장할 내역이 없습니다.");
      } else {
        saveTransactionSubList.mutate(transactionSubList);
      }
    }
  };
  const btnDelete = (deleteEl, deleteIdx) => {
    if (deleteEl.newCheck) {
      const list = transactionSubList.filter((_, idx) => idx !== deleteIdx);
      setTransactionSubList(list);
    } else {
      if (confirm("삭제하시겠습니까?")) {
        deleteTransactionSubList.mutate(deleteEl);
      }
    }
  };

  return (
    <>
      <div className="card card-main">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="table-tabs-sub w-50 m-0">회원권 거래내역</div>
            <div className="tab1-row1-field1 w-50 justify-content-end">
              <button className="btn btn-outline-primary" onClick={btnAdd}>
                추가
              </button>
              <button className="btn btn-outline-primary ms-8px" onClick={btnSave}>
                저장
              </button>
            </div>
          </div>

          <table className="table table-bordered table-responsive my-table mt-3 mb-0">
            <thead>
              <tr>
                <th style={{ width: "3%" }}>연번</th>
                <th style={{ width: "5%" }}>매매</th>
                <th style={{ width: "5%" }}>구분</th>
                <th style={{ width: "6%" }}>코드</th>
                <th style={{ width: "12%" }}>종목명</th>
                <th style={{ width: "5%" }}>회원권구분</th>
                <th style={{ width: "8%" }}>구좌</th>
                <th style={{ width: "7.5%" }}>매매금액</th>
                <th style={{ width: "7.5%" }}>중개계약금</th>
                <th style={{ width: "7.5%" }}>중개수수료</th>
                <th style={{ width: "7.5%" }}>개서료</th>
                <th style={{ width: "7.5%" }}>인지세</th>
                <th style={{ width: "7.5%" }}>추가금액</th>
                <th style={{ width: "7.5%" }}>잔금</th>
                <th style={{ width: "3%" }}>삭제</th>
              </tr>
            </thead>
            <tbody>
              {transactionSubList.length !== 0 ? (
                transactionSubList?.map((el, idx) => {
                  return (
                    <tr>
                      <td>{isAdd ? (idx > 0 ? idx : "") : idx + 1}</td>
                      {/* 연번 */}
                      <td>
                        {/* 매매 */}
                        <select
                          className="form-select"
                          value={el?.tradGubn}
                          onChange={e =>
                            onChangeSubListEventHandler("tradGubn", e.target.value, idx)
                          }
                        >
                          <option value="D">매도</option>
                          <option value="S">매수</option>
                        </select>
                      </td>
                      <td>
                        {/* 구분 */}
                        <select
                          className="form-select"
                          value={el?.tradClass}
                          onChange={e =>
                            onChangeSubListEventHandler("tradClass", e.target.value, idx)
                          }
                        >
                          <option value="1">골프</option>
                          <option value="2">콘도</option>
                          <option value="3">헬스</option>
                        </select>
                      </td>
                      <td>
                        {/* 코드 */}
                        <input
                          type="text"
                          className="form-control"
                          value={el?.tradCode}
                          readOnly={true}
                        />
                      </td>
                      <td>
                        {/* 종목명 */}
                        {/*<select
                          className="form-select"
                          value={el.tradCode}
                          onChange={e => {
                            onChangeSubListEventHandler("tradCode", e.target.value, idx);
                          }}
                        >
                          {el.tradClass === "1"
                            ? GMOptions
                            : el.tradClass === "2"
                            ? CMOptions
                            : el.tradClass === "3"
                            ? SMOptions
                            : ""}
                        </select>*/}
                        <Select
                          className="select-virtual text-left"
                          menuPlacement={"auto"}
                          menuPosition={"fixed"}
                          options={el.tradClass === "1"
                            ? etccodeGM.data?.map(item => ({ value: item.key, label: item.value }))
                            : el.tradClass === "2"
                              ? etccodeCM.data?.map(item => ({ value: item.key, label: item.value }))
                              : el.tradClass === "3"
                                ? etccodeSM.data?.map(item => ({ value: item.key, label: item.value }))
                                : []
                          }
                          value={el.tradClass === "1"
                            ? {
                              value: etccodeGM.data?.find(item => item.key === el.tradCode)?.key,
                              label: etccodeGM.data?.find(item => item.key === el.tradCode)?.value,
                            }
                            : el.tradClass === "2"
                              ? {
                                value: etccodeCM.data?.find(item => item.key === el.tradCode)?.key,
                                label: etccodeCM.data?.find(item => item.key === el.tradCode)?.value,
                              }
                              : el.tradClass === "3"
                                ? {
                                  value: etccodeSM.data?.find(item => item.key === el.tradCode)?.key,
                                  label: etccodeSM.data?.find(item => item.key === el.tradCode)?.value,
                                }
                                : { value: "", label: "" }}
                          onChange={e => e === null ? "" : onChangeSubListEventHandler("tradCode", e.value, idx)}
                        />
                      </td>
                      <td>
                        {" "}
                        {/* 회원권구분 */}
                        <select
                          className="form-select"
                          value={el?.gubnName}
                          onChange={e =>
                            onChangeSubListEventHandler("gubnName", e.target.value, idx)
                          }
                        >
                          <option value="정회원">정회원</option>
                          <option value="법인">법인</option>
                          <option value="주중회원">주중회원</option>
                          <option value="분담금">분담금</option>
                        </select>
                      </td>
                      <td>
                        {" "}
                        {/* 구좌 */}
                        <select
                          className="form-select"
                          value={el?.kindFlag}
                          onChange={e =>
                            onChangeSubListEventHandler("kindFlag", e.target.value, idx)
                          }
                        >
                          {getEstimateMiddleKind.data?.map(el => {
                            return <option value={el.key}>{el.value}</option>;
                          })}
                        </select>
                      </td>
                      <td>
                        {" "}
                        {/* 매매금액 */}
                        <input
                          type="text"
                          className="form-control text-end"
                          value={el?.totalAmnt ? Number(el?.totalAmnt).toLocaleString() : 0}
                          onChange={e => {
                            let value = e.target.value.replace(/\D/g, "");
                            value = value === "" ? "0" : value;
                            onChangeSubListEventHandler("totalAmnt", value, idx);
                          }}
                        />
                      </td>
                      <td>
                        {" "}
                        {/* 중개계약금 */}
                        <input
                          type="text"
                          className="form-control text-end"
                          value={el?.firstAmnt ? Number(el?.firstAmnt).toLocaleString() : 0}
                          onChange={e => {
                            let value = e.target.value.replace(/\D/g, "");
                            value = value === "" ? "0" : value;
                            onChangeSubListEventHandler("firstAmnt", value, idx);
                          }}
                        />
                      </td>
                      <td>
                        {" "}
                        {/* 중개수수료 */}
                        <input
                          type="text"
                          className="form-control text-end"
                          value={el?.commiAmnt ? Number(el?.commiAmnt).toLocaleString() : 0}
                          onChange={e => {
                            let value = e.target.value.replace(/\D/g, "");
                            value = value === "" ? "0" : value;
                            onChangeSubListEventHandler("commiAmnt", value, idx);
                          }}
                        />
                      </td>
                      <td>
                        {" "}
                        {/* 개서료 */}
                        <input
                          type="text"
                          className="form-control text-end"
                          value={el?.writeAmnt ? Number(el?.writeAmnt).toLocaleString() : 0}
                          onChange={e => {
                            let value = e.target.value.replace(/\D/g, "");
                            value = value === "" ? "0" : value;
                            onChangeSubListEventHandler("writeAmnt", value, idx);
                          }}
                          /*readOnly={el?.tradGubn === "D"}*/
                          /*style={el?.tradGubn === "D" || el?.tradClass === "1" ? {border: "0"} : {}}*/
                        />
                      </td>
                      <td>
                        {" "}
                        {/* 인지세 */}
                        <input
                          type="text"
                          className="form-control text-end"
                          value={el?.stampAmnt ? Number(el?.stampAmnt).toLocaleString() : 0}
                          onChange={e => {
                            let value = e.target.value.replace(/\D/g, "");
                            value = value === "" ? "0" : value;
                            onChangeSubListEventHandler("stampAmnt", value, idx);
                          }}
                          /*readOnly={el?.tradGubn === "D"}*/
                          /*style={el?.tradGubn === "D" || el?.tradClass === "1" ? { border: "0" } : {}}*/
                        />
                      </td>
                      <td>
                        {" "}
                        {/* 추가금액 */}
                        <input
                          type="text"
                          className="form-control text-end"
                          value={el?.addedAmnt ? Number(el?.addedAmnt).toLocaleString() : 0}
                          onChange={e => {
                            let value = e.target.value.replace(/\D/g, "");
                            value = value === "" ? "0" : value;
                            onChangeSubListEventHandler("addedAmnt", value, idx);
                          }}
                        />
                      </td>
                      <td>
                        {" "}
                        {/* 잔금 */}
                        <input
                          type="text"
                          className="form-control text-end"
                          value={el?.remainAmnt ? Number(el?.remainAmnt).toLocaleString() : 0}
                          onChange={e => {
                            let value = e.target.value.replace(/\D/g, "");
                            value = value === "" ? "0" : value;
                            onChangeSubListEventHandler("remainAmnt", value, idx);
                          }}
                        />
                      </td>
                      <td>
                        {" "}
                        {/* 삭제 */}
                        <a onClick={() => btnDelete(el, idx)}>
                          <img src={process.env.PUBLIC_URL + "/images/icon-trash.png"} alt="" />
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={15}>검색된 결과가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default React.memo(TransactionProgressSub);
