import { decrypt } from "./decrypt/decrypt";

/**
 * 1 - 전체 지사 및 직원 조회 권한
 * 0 - 본인 지사만 조회 권한
 * -1 - 본인만 조회 권한
 * @returns {string}
 */
export default function lockAuth2() {
  const userPermit = decrypt("userInfo").userPermit;
  const job = decrypt("userInfo").job;

  // 본사직원이자 관리팀원일 때
  if (["24"].includes(userPermit) && !["74", "78", "83"].includes(job)) {
    return "0";
  } else {
    return "-1";
  }
}
