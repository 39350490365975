import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../../../util/axios/axios";
import { useAtomValue } from "jotai";
import moment from "moment";
import { DaumPostcode } from "../../../../../components/daumPostcode/daumPostcode";
import { transactionHistoryClickedRow_atom } from "../../../../../data/atom/workManage/transactionRegisterManage/transactionHistory_atom";
import { useGetUserIdQ } from "../../../../../api/code/useGetUserIdQ";
import { regex_only_number } from "../../../../../util/validation";
import * as yup from "yup";

const init = {
  seqNo: "",
  kname: "",
  juminno: "",
  juminno1: "",
  juminno2: "",
  modId: "",
  modDate: "",
  rhBdcode: "",
  rhZipcode: "",
  rhAddres: "",
  rhAreaCode: "",
  rhAddresTxt: "",
  same: "",
  baddres: "",
  baddresTxt: "",
  bzipcode: "",
  custId: "",
};

const validationErrorsInit = {
  seqNo: "",
  kname: "",
  juminno: "",
};

const validationSchem = yup.object().shape({
  seqNo: yup.string().required("고객을 선택해 주세요."),
  kname: yup.string().required("성명을 입력해 주세요.").min(2, "2자 이상 입력해 주세요."),
  juminno: yup
    .string() // <- 숫자가 아닌 문자열로 검증
    .matches(/^\d{13}$/, "-를 제외한 13자리 숫자를 입력해 주세요."),
});

const searchResultListRight1 = () => {
  const [myData, setMyData] = useState({ ...init });
  const atomData = useAtomValue(transactionHistoryClickedRow_atom);
  const userIdQ = useGetUserIdQ();
  const [validationErrors, setValidationErrors] = useState({ ...validationErrorsInit });

  const transactionRightGetMemberInfo = useMutation(
    data =>
      axios.get("/api/work/transaction/right/getMemberInfo", {
        params: { seqNo: data.seqNo },
      }),
    {
      onSuccess: res => {
        const { result } = res.data;
        if (result.juminno) {
          result.juminno1 = result.juminno.substring(0, 6);
          result.juminno2 = result.juminno.substring(6);
        } else {
          result.juminno1 = "";
          result.juminno2 = "";
        }
        setMyData({ ...result });
      },
      onError: ({ response }) => {
        setMyData(init);
      },
    }
  );

  const transactionRightSaveMemberInfo = useMutation(
    data =>
      axios.post("/api/work/transaction/right/saveMemberInfo", {
        baddres: myData.baddres,
        bzipcode: myData.bzipcode,
        juminno: String(myData.juminno), // <- String으로 변환
        kname: myData.kname,
        rhAddres: myData.rhAddres,
        rhBdcode: myData.rhBdcode,
        rhZipcode: myData.rhZipcode,
        seqNo: myData.seqNo,
      }),
    {
      onSuccess: res => {
        transactionRightGetMemberInfo.mutate(atomData);
      },
    }
  );

  useEffect(() => {
    if (atomData && atomData.seqNo) {
      if (atomData.seqNo != myData.seqNo) transactionRightGetMemberInfo.mutate(atomData);
    } else setMyData(init);
  }, [atomData]);

  const onChangeEvent = (key, value) => {
    if (value) value = value.replaceAll("-", "");

    let tmp = { ...myData };

    switch (key) {
      case "juminno1":
        if (!regex_only_number.test(value)) return;
        tmp = { ...tmp, juminno: value + (tmp.juminno2 ?? ""), [key]: value };
        break;
      case "juminno2":
        if (!regex_only_number.test(value)) return;
        tmp = { ...tmp, juminno: (tmp.juminno1 ?? "") + value, [key]: value };
        break;
      default:
        tmp = { ...myData, [key]: value };
    }

    setMyData(tmp);
  };

  const btn_save = async () => {
    if (!(atomData && atomData.seqNo)) return;
    try {
      await validationSchem.validate(myData, { abortEarly: false });
      setValidationErrors({ ...validationErrorsInit });
    } catch (e) {
      let tmp = { ...validationErrorsInit };
      for (var err of e.inner) tmp = { ...tmp, [err.path]: err.errors[0] };
      setValidationErrors(tmp);
      return;
    }
    transactionRightSaveMemberInfo.mutate(myData);
  };

  const btn_delete = () => {
    if (confirm("정말 삭제 하시겠습니까?")) {
      setMyData({
        ...myData,
        baddres: "",
        bzipcode: "",
        baddresTxt: "",
        juminno: "",
        kname: "",
        rhAddres: "",
        rhBdcode: "",
        rhZipcode: "",
        rhAddresTxt: "",
      });
      alert("삭제 되었습니다.");
    } else alert("취소 되었습니다.");
  };

  return (
    <div className="tab-content-container">
      <div className="table-tabs-sub d-flex">
        <div>회원증 정보</div>
        <div className="table-tabs-caption">
          (회원증 명의와 상이할 경우 회원증 정보로 저장할 것)
        </div>
      </div>

      <table className="table table-bordered table-responsive my-table1">
        <tbody>
          <tr>
            <th style={{ width: "13%" }}>
              <span style={{ color: "red" }}> * </span>관리번호
            </th>
            <td style={{ width: "37%" }}>
              <input
                type="text"
                className={`${
                  validationErrors.seqNo !== "" ? "" : "form-control"
                } table-input w-100 `}
                value={
                  myData.custId && `${myData.custId.substring(0, 4)}-${myData.custId.substring(4)}`
                }
                readOnly={true}
              />
            </td>
            <th style={{ width: "13%" }}>
              <span style={{ color: "red" }}> * </span>성명
            </th>
            <td style={{ width: "37%" }}>
              <input
                type="text"
                className={`${
                  validationErrors.kname !== "" ? "" : "form-control"
                } table-input w-100`}
                value={myData.kname ?? ""}
                onChange={e => onChangeEvent("kname", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>
              <span style={{ color: "red" }}> * </span>주민번호
            </th>
            <td colSpan={3}>
              <div className="d-flex">
                <input
                  type="text"
                  className={`${
                    validationErrors.juminno !== "" ? "" : "form-control"
                  } table-input table-input-inline`}
                  maxLength={6}
                  value={myData.juminno1 ?? ""}
                  onChange={e => onChangeEvent("juminno1", e.target.value)}
                />
                <div className="txt_dash">-</div>
                <input
                  type="text"
                  className="form-control table-input table-input-inline"
                  maxLength={7}
                  value={myData.juminno2 ?? ""}
                  onChange={e => onChangeEvent("juminno2", e.target.value)}
                />
                <div
                  style={{
                    whiteSpace: "nowrap",
                    background: `${myData.same ? "green" : "red"}`,
                    color: "white",
                  }}
                >
                  {myData.same !== "" && (myData.same ? "(동일)" : "(상이)")}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th rowSpan={2}>등본주소</th>
            <td colSpan={3}>{myData?.bzipcode + " " + myData?.baddresTxt}</td>
          </tr>
          <tr>
            <td colSpan={3}>{myData?.baddres}</td>
          </tr>
          <tr>
            <th rowSpan={2}>도로명 주소</th>
            <td colSpan={1}>
              <input
                type="text"
                className="form-control table-input input-of-button"
                value={myData.rhZipcode ?? ""}
                onChange={e => onChangeEvent("rhZipcode", e.target.value)}
              />
              <DaumPostcode
                onCompleteCallback={data =>
                  setMyData({
                    ...myData,
                    rhZipcode: data.zonecode,
                    rhBdcode: data.bdCode,
                    rhAddresTxt: data.fullAddr,
                  })
                }
              />
            </td>
            <td colSpan={3}>
              <input
                type="text"
                className="form-control table-input"
                value={myData.rhAddresTxt ?? ""}
                onChange={e => onChangeEvent("rhAddresTxt", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <input
                type="text"
                className="form-control table-input w-100"
                value={myData.rhAddres ?? ""}
                onChange={e => onChangeEvent("rhAddres", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th>최종 수정자</th>
            <td>
              {userIdQ.data &&
                (userIdQ.data?.find(item => item.key === myData?.modId)?.value ?? "")}
            </td>
            <th>최종 수정일</th>
            <td>{myData.modDate ? moment(myData.modDate).format("YYYY-MM-DD HH:mm:ss") : ""}</td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex align-items-center justify-content-end mb-8px">
        {/* <button className="btn btn-outline-danger tab1-row1-btn" onClick={btn_delete}>
          삭제
        </button> */}
        <button className="btn btn-primary tab1-row1-btn" onClick={btn_save}>
          등록
        </button>
      </div>
    </div>
  );
};
export default searchResultListRight1;
